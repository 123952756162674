import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BiCheckDouble, BiTime, BiCheck } from 'react-icons/bi';
import { FiMoreHorizontal, FiMoreVertical } from 'react-icons/fi';
import { Alert, Button, IconButton, Menu, MenuItem, Snackbar } from '@mui/material';
import { api } from 'api/api';
import { generateChatId } from 'hooks/Utils/Utils';
import messagingKeys from 'constants/messagingKeys';
import { firestoreDB } from 'services/Firebase/firebase';
import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { doc, updateDoc } from 'firebase/firestore';

const MemberCard = ({
    isPending,
    conv_id,
    user,
    group,
    groupFirestore,
    onRefresh
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const { data: authReducerState } = useAuthReducer()


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };




    const handleChangeRole = async (role) => {
        try {
            handleClose()
            const data = {
                user_id: user?.user_id,
                role: role
            }
            const request = await api(
                `api/conversations/${conv_id}/change_user_role`,
                'POST',
                data,
                accessToken
            )
            const response = await request.json()
            console.log('Response Change user role:', response)
            if (request.status === 200) {
                if (response.success) {
                    onRefresh()
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    const handleRemoveMember = async () => {
        try {
            handleClose()
            const data = {
                user_id: user?.user_id
            }
            const request = await api(
                `api/conversations/${conv_id}/remove_user`,
                'POST',
                data,
                accessToken
            )
            const response = await request.json()
            console.log('Response remove user:', response)
            if (request.status === 200) {
                if (response.success) {

                    const chat_id = generateChatId(messagingKeys.groupChat, group?.conv_id, authReducerState?.user?.user_id)
                    const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());

                    const newRequests = groupFirestore?.requests?.filter(item =>
                        item !== user?.user_id
                    )
                    const newParticipants = groupFirestore?.participants?.filter(item =>
                        item !== user?.user_id
                    )

                    await updateDoc(docRef, {
                        requests: newRequests,
                        participants: newParticipants
                    }).then(() => {
                        console.log('Group chat remove to firstore.');
                    }).catch((error) => {
                        console.error('Error remove user group chat:', error);
                    });

                    onRefresh()
                }
            }
        } catch (error) {
            console.error(error);
        }
    }



    const handleCancelInvite = async () => {
        try {
            const data = {
                user_id: user?.user_id
            }
            const request = await api(
                `api/conversations/${conv_id}/cancel_invitation`,
                'POST',
                data,
                accessToken
            )
            const response = await request.json()
            // console.log('Response cancel invitation:', response)
            if (request.status === 200) {
                if (response.success) {

                    const chat_id = generateChatId(messagingKeys.groupChat, group?.conv_id, authReducerState?.user?.user_id)
                    const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());

                    const newRequests = groupFirestore?.requests?.filter(item =>
                        item !== user?.user_id
                    )
                    const newParticipants = groupFirestore?.participants?.filter(item =>
                        item !== user?.user_id
                    )

                    await updateDoc(docRef, {
                        requests: newRequests,
                        participants: newParticipants
                    }).then(() => {
                        console.log('Group chat remove to firstore.');
                    }).catch((error) => {
                        console.error('Error remove user group chat:', error);
                    });

                    onRefresh()
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <>
            <div className='flex items-center py-2'>

                <div onClick={() => navigate(`/profile/${user?.user_username}`)} className='flex items-center cursor-pointer'>
                    <img src={user?.profile?.prof_picture || require('assets/images/background_opinion.jpg')} className='w-10 h-10 ring-1 ring-gray-200 me-2 rounded-full object-cover object-center' alt='' />
                    <div>
                        <p className='text-sm'>{user?.user_name} {user?.user_surname}</p>
                        <p className='text-xs text-gray-500'>@{user?.user_username}</p>
                    </div>
                </div>

                {!isPending ?
                    <p className='text-xs font-medium py-1 px-2 ms-auto me-2 ring-1 ring-orange-500 rounded-sm text-orange-500'>
                        {user?.user_id === group?.creator?.user_id
                            ? 'Super admin'
                            : user?.pivot?.role == 'A'
                                ? 'Admin'
                                : user?.pivot?.role == 'M'
                                    ? i18n.language == 'en'
                                        ? 'Moderator'
                                        : 'Moderateur'
                                    : i18n.language == 'en'
                                        ? 'Moderator'
                                        : 'Participant'
                        }
                    </p>
                    :
                    <button onClick={handleCancelInvite} className='text-xs font-medium py-1 px-2 ms-auto me-2 ring-1 ring-orange-500 rounded-sm text-orange-500'>
                        {t('cancel')}
                    </button>
                }
                {user?.user_id !== group?.creator?.user_id && authReducerState?.user?.user_id == group?.creator?.user_id && !isPending ?
                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <FiMoreVertical size={18} color='#414141' />
                    </IconButton>
                    :
                    <div className='ms-4' />
                }
            </div>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem onClick={() => handleChangeRole('P')}>
                    {i18n.language == 'en'
                        ? 'Make participant'
                        : 'Rendre participant'
                    }
                </MenuItem>
                <MenuItem onClick={() => handleChangeRole('M')}>
                    {i18n.language == 'en'
                        ? 'Make Moderator'
                        : 'Rendre Moderateur'
                    }
                </MenuItem>
                <MenuItem onClick={() => handleChangeRole('A')}>
                    {i18n.language == 'en'
                        ? 'Make Administrator'
                        : 'Rendre Administrateur'
                    }
                </MenuItem>
                <MenuItem onClick={handleRemoveMember}>
                    {i18n.language == 'en'
                        ? 'Remove from group'
                        : 'Retirer du groupe'
                    }
                </MenuItem>
            </Menu>
        </>
    )
}

export default MemberCard