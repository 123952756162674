import React, { memo } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiSendPlaneFill } from 'react-icons/ri'
import { api } from '../../api/api'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import './CommentInput.style.css'
import InputEmoji from "react-input-emoji";
import useNotificationPush from '../../hooks/useNotificationPush/useNotificationPush'
import { color } from '../../hooks/Utils/color'
import Switch from "react-switch";


const CommentInput = ({
    sond_id,
    com_user,
    com_type,
    com_text = '',
    com_parent = 0,
    sub_com_id = 0,
    sond_user,
    sond_caption,
    updateOpinion =()=>null,
    setShowUnauthModal,
    sourceFrom
}) => {

    const { data: AuthData } = useAuthReducer()
    const accessToken = useAccessToken()
    const sendNotification = useNotificationPush()
    const { t, i18n } = useTranslation()
    const [textComment, setTextComment] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [showEmoji, setShowEmoji] = useState(false)

    const [com_anonymous, setCom_anonymous] = useState(0)

    const handleComment = async (e) => {
        try {
            if (e) {
                e.preventDefault();
            }
            if (sourceFrom == 'Feed') {
                setShowUnauthModal(true)
                return
            }
            setIsLoading(true)

            const body = {
                com_user: com_user,
                com_sond: sond_id,
                com_type: com_type,
                com_text: textComment,
                com_parent: com_parent,
                sub_com_id: sub_com_id,
                com_anonymous: com_anonymous
            }
            const request = await api(`api/comments/v2/store`, 'POST', body, accessToken)
            const response = await request.json()
            console.log(response)
            if (request.ok && request.status === 200) {
                setTextComment('')
                setError('')
                updateOpinion(response.opinion)
            } else {
                setError(t('incorrect_email_address_or_password'))
            }
            setIsLoading(false)
        } catch (error) {
            setError(t('an_error_occurred_please_try_again_later'));
            setIsLoading(false)
            console.error(error);
        }
    }

    return (
        <div>
            <form onSubmit={handleComment} className='comment-input-component'>

                <ProfilePicture
                    user={AuthData?.user}
                    uri={AuthData?.user?.profile?.prof_picture}
                    size={33}
                />

                <InputEmoji
                    value={textComment}
                    onChange={setTextComment}
                    cleanOnEnter
                    keepOpened
                    onEnter={text => handleComment()}
                    theme={'light'}
                    placeholder="Commentez..."
                />
                <button type='submit' onClick={handleComment} >
                    <RiSendPlaneFill size={26} color={color.primary} style={{ backgroudColor: color.primary, border: `1px ${color.primary} solid`, borderRadius: '30px', padding: '3px' }} />
                </button>
            </form>

            {textComment &&
                <div style={{
                    paddingLeft: '65px',
                    paddingRight: '65px'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontWeight: '600', fontSize: '14px', marginRight: '10px' }}>
                            {i18n.language == 'en'
                                ? 'Anonymous comment'
                                : 'Commentaire anonyme'
                            }
                        </span>
                        <Switch
                            checked={com_anonymous === 1}
                            onChange={checked => setCom_anonymous(checked ? 1 : 0)}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={color.primary}
                            handleDiameter={20}
                            height={25}
                        />
                    </div>
                </div>
            }
            
        </div>
    )
}

export default memo(CommentInput)