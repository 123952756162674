// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpymw-oAQDyg16zJMzNrtPBMUEzMz0S6s",
  authDomain: "tresor-public-civ-social-hub.firebaseapp.com",
  projectId: "tresor-public-civ-social-hub",
  storageBucket: "tresor-public-civ-social-hub.appspot.com",
  messagingSenderId: "764091859320",
  appId: "1:764091859320:web:83a02df13149423caa488d",
  measurementId: "G-VV79B7QEMM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firestoreDB = getFirestore(app)

export const firestoreKeystore = process.env.REACT_APP_SCHEME



