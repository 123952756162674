export const apiUrl = process.env.REACT_APP_API_URL
export const appUrl = process.env.REACT_APP_WEB_URL
export const agoraApiLive = process.env.REACT_APP_AGORA_API_LIVE
export const agoraApiGenerateKey = process.env.REACT_APP_AGORA_API_GENERATE_KEY

export const api = async (
    endpoint,
    method,
    bodyValue = null,
    endpointHeaders = {},
) => {
    
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        ...endpointHeaders,
    }

    const body = JSON.stringify(bodyValue)

    const request = fetch(`${apiUrl}/${endpoint}`, {
        method,
        headers,
        body: method === 'GET' ? null : body,
    })
    return request
}

