'use client';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks';
import { addDoc, collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { GrGallery } from "react-icons/gr";
import { firestoreDB } from 'services/Firebase/firebase';
import { appUrl } from 'api/api';
import { apiUrl } from 'api/api';
import firestorekeys from 'constants/firestorekeys';
import { api } from 'api/api';
import avatar from 'assets/images/background_opinion.jpg'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import { generateChatId } from 'hooks/Utils/Utils';
import messagingKeys from 'constants/messagingKeys';
import { scheme } from 'constants/env';

const MessageForward = ({
    chatId,
    userChat,
    buddies = [],
    message = null,
    visible = false,
    onChangeVisible = () => null,
    onRefresh = () => null
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authContextState } = useAuthReducer()
    const navigate = useNavigate()

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [data, setData] = useState(buddies)
    const [page, setPage] = useState(1)
    const [hasMorePage, setHasMorePage] = useState(false)

    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const [searchText, setSearchText] = useState('')

    const handleCancel = () => {
        setIsLoading(false)
        setSelectedUsers([])
        onChangeVisible(false)
    }

    useEffect(() => {
        if (searchText) {
            const newData = buddies.filter(
                item =>
                    item?.user_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                    item?.user_surname?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                    item?.user_username?.toLowerCase()?.includes(searchText?.toLowerCase()),
            )
            setData(newData)
        } else {
            setData(buddies)
        }
    }, [searchText])


    const handleSelectUser = (user) => {
        if (selectedUsers.includes(user)) {
            setSelectedUsers(selectedUsers.filter((u) => u !== user));
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    };

    // Function to send invite to selected users
    const handleInvite = () => {
        setIsLoading(true)
        if (selectedUsers.length !== 0) {
            selectedUsers?.forEach(item => {
                forwardMessageFirestore(item)
            })
        }
    };


    const forwardMessageFirestore = async (user) => {
        try {

            const newChatId = generateChatId(messagingKeys.oneToOneChat, user?.user_id, authContextState?.user?.user_id)

            const message_id = new Date().getTime()

            const messageForward = {
                ...message,
                _id: message_id,
                uid: message_id,
                user: {
                    _id: authContextState?.user?.user_id,
                    name: `${authContextState?.user?.user_surname || ''} ${authContextState?.user?.user_name || ''}`,
                },
                forwarded: true,
                sender_id: authContextState?.user?.user_id,
                status: 'no'
            }

            const docRef = doc(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                newChatId,
                firestorekeys.messages,
                message_id?.toString()
            )

            await setDoc(docRef, messageForward).then(() => {
                console.log('Firestore message forwarded.');
                onForward(user?.user_id)
            }).catch(() => {
                console.error('Error save firestore message');
            })

            const docConvRef = doc(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                newChatId
            )
            
            await setDoc(docConvRef, {
                chat_id: newChatId,
                conversation_id: user?.user_id,
                conversation_type: messagingKeys.oneToOneChat,
                users: [
                    authContextState?.user,
                    user
                ],
                participants: [
                    user?.user_id,
                    authContextState?.user?.user_id
                ],
                last_message: messageForward,
                timestamp: message_id
            }).then(() => {
                console.log('Firestore user saved.');
            }).catch((e) => {
                console.error('Error user save firestore message.', e);
            })

        } catch (error) {
            setIsLoading(false)
            console.error(error);
        }
    }

    const onForward = async (user_id) => {
        try {
            const body = {
                receiver_id: user_id,
                message_ids: [message?._id]
            }
            const request = await api('api/forwardMessages', 'POST', body, accessToken)
            const response = await request.json()
            setIsLoading(false)
            console.log('Forwart response:', response)
            if (request.status === 200) {
                if (response.success) {
                    handleCancel()
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error);
        }
    }


    return (
        <Dialog open={visible} onClose={() => onChangeVisible(false)} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
                    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

                        <div className="bg-white px-4 pb-4 pt-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="w-full  sm:ml-4 sm:text-left">

                                    <DialogTitle as="h3" className="text-base text-center font-semibold leading-6">
                                        {i18n.language == 'en'
                                            ? 'Forward message'
                                            : 'Transférer message'
                                        }
                                    </DialogTitle>

                                    <div>
                                        <input
                                            type='search'
                                            placeholder={i18n.language == 'en'
                                                ? 'Search...'
                                                : 'Recherche...'
                                            }
                                            onChange={(e) => setSearchText(e.target.value)}
                                            className='pt-1'
                                        />
                                    </div>

                                    {selectedUsers.length != 0 &&
                                        <h3 className='font-bold mb-2'>
                                            {i18n.language == 'en'
                                                ? 'Selected users'
                                                : 'Utilisateurs selectionnés'
                                            }
                                        </h3>
                                    }
                                    <div className='flex overflow-x-auto mb-6 w-11/12'>
                                        {selectedUsers.map((user, index) => (
                                            <div key={index} className='text-center me-3'>
                                                <img
                                                    src={user?.profile?.prof_picture || avatar}
                                                    alt=''
                                                    style={{
                                                        width: '32px',
                                                        height: '32px',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                    className='object-cover object-center rounded-full'
                                                />
                                                <div>
                                                    <p className='text-xs font-bold p-0 m-0'>
                                                        {`${user?.user_surname} ${user?.user_name}`.length > 9
                                                            ? `${user?.user_surname} ${user?.user_name}`?.slice(0, 9) + '...'
                                                            : `${user?.user_surname} ${user?.user_name}`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <h3 className='font-bold'>
                                        {i18n.language == 'en'
                                            ? 'Users'
                                            : 'Utilisateurs'
                                        }
                                    </h3>
                                    <div className='overflow-y-auto h-96'>
                                        {data?.map((user, index) => {
                                            return (
                                                <div key={index} onClick={() => handleSelectUser(user)} className='flex items-center py-3 border-b hover:bg-gray-100 cursor-pointer'>
                                                    <input
                                                        type='radio'
                                                        checked={selectedUsers.includes(user)}
                                                        onChange={() => handleSelectUser(user)}
                                                        className='me-3'
                                                    />
                                                    <img src={user?.profile?.prof_picture || avatar} alt='' className='w-12 h-12 object-cover object-center rounded-full me-3' />
                                                    <div>
                                                        <p className='font-bold p-0 m-0'>
                                                            {user?.user_surname || ''} {user?.user_name || ''}
                                                        </p>
                                                        <span className='text-sm'>
                                                            @{user?.user_username}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                onClick={handleInvite}
                                type="submit"
                                className="inline-flex w-full justify-center mt-2 rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 sm:ml-3 sm:w-auto">
                                {isLoading ?
                                    <div role="status" className='mx-4'>
                                        <svg aria-hidden="true" className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <span> {t('invite')}</span>
                                }
                            </button>
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="border border-emerald-600 inline-flex w-full justify-center   mt-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default MessageForward