import { agoraApiGenerateKey, appUrl } from 'api/api';
import axios from 'axios'

export const generateChannelName = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
}

export const generateBroadcastToken = async () => {
    try {
        const channel = generateChannelName(4)
        const response = await axios.get(agoraApiGenerateKey, {
            params: {
                channel_name: channel
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        console.log('Response generate broadcast token:', response.data)
        return response.data
    } catch (error) {
        return null
    }
}

