import firestorekeys from 'constants/firestorekeys';
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { firestoreDB } from 'services/Firebase/firebase';
import avatar from 'assets/images/background_opinion.jpg'
import styles from './LiveComment.module.css'
import { scheme } from 'constants/env';


const LiveComment = ({
    channelName,
    onChangeCommentCount = () => null
}) => {

    const [comments, setComments] = useState([])

    useEffect(() => {
        const q = query(
            collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels,
                channelName,
                firestorekeys.comments
            ),
            orderBy('timestamp', 'desc')
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const comments = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            onChangeCommentCount(querySnapshot.size)
            setComments(comments?.slice(0, 6)?.reverse())
        });
        return () => {
            unsubscribe();
        }
    }, [channelName]);


    return (
        <div className='overflow-auto'>
            {comments?.map((comment, index) => {
                return (
                    <div key={index} className='flex items-center mt-3'>
                        <img
                            src={comment?.user?.profile?.prof_picture || avatar}
                            alt=''
                            className='w-9 rounded-full me-2 border border-gray-500'
                        />
                        <div>
                            <span className='text-white text-xs font-bold'>
                                {comment?.user?.user_surname} {comment?.user?.user_name}
                            </span>
                            <p className='text-white text-sm'>
                                {comment?.text}
                            </p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default LiveComment