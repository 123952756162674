import React, { memo, useCallback, useEffect, useState } from 'react'
import ProfilePicture from '../ProfilePicture/ProfilePicture'
import './Comment.style.css'
import Moment from 'react-moment'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { api } from '../../api/api'
import CommentInput from '../CommentInput/CommentInput'
import { Modal } from 'react-bootstrap'
import Header from '../Header/Header'
import InputEmojiWithRef from 'react-input-emoji'
import { RiSendPlaneFill } from 'react-icons/ri'
import axios from 'axios'
import { color } from '../../hooks/Utils/color'
import TextTranslate from 'components/TextTranslate/TextTranslate'
import { useTranslation } from 'react-i18next'

const Comment = ({
    user,
    sond_id,
    sond_caption,
    sond_user,
    com_id,
    sub_com_id = 0,
    com_mute,
    com_parent,
    com_sond,
    com_text = '',
    com_type,
    com_user,
    com_anonymous = false,
    attachment,
    soutiens = [],
    soutiens_count = 0,
    subcomments = [],
    subcomments_count,
    com_date_created,
    sourceFrom,
    updateOpinion = () => null,
    setShowUnauthModal
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: AuthData } = useAuthReducer()
    Moment.globalLocale = localStorage.getItem('language');
    const [isReply, setIsReply] = useState(false)
    const [isLiked, setIsLiked] = useState(false)
    const [soutiensCountState, setSoutiensCountState] = useState(0)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [textComment, setTextComment] = useState('')

    useEffect(() => {
        setSoutiensCountState(soutiens_count)
        setIsLiked(soutiens.some(value => value.sout_user == AuthData?.user?.user_id))
    }, [soutiens_count, soutiens])

    const onChangeLikeUi = useCallback(() => {
        if (sourceFrom == 'Feed') {
            setShowUnauthModal(true)
            return
        }

        if (isLiked) {
            setSoutiensCountState(soutien => --soutien)
            setIsLiked(false)
        } else {
            setSoutiensCountState(soutien => ++soutien)
            setIsLiked(true)
        }
    }, [isLiked, com_parent])



    const handleLikeComment = async () => {
        onChangeLikeUi()
        if (!isLiked) {
            try {
                const body = { sout_opinion: sond_id, sout_comment: com_id }
                const request = await api('api/soutiens', 'POST', body, accessToken)
                const response = await request.json()
                console.log(response, 'response of like comment ')
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        updateOpinion(response.opinion)
                        // if (com_user !== AuthData?.user?.user_id) {
                        //     sendNotification(com_user, {
                        //         title: `${authContextState?.user?.user_surname} ${authContextState?.user?.user_name
                        //             } a liké votre ${com_type === 'c' ? 'commentaire' : 'suggestion'
                        //             } :`,
                        //         body: notificationBody,
                        //         url: `https://myopinion.ci/opinion/select/0/${sond_id}`,
                        //     })
                        // }
                    }
                }
            } catch (error) {
                throw new Error(error.message)
            }
        } else {
            try {
                const request = await api(
                    `api/soutiens/${soutiens[0].sond_id}`,
                    'DELETE',
                    null,
                    accessToken,
                )
                const response = await request.json()
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        updateOpinion(response.opinion)
                    }
                }
            } catch ({ message }) {
                throw new Error(message)
            }
        }
    }

    const hanndleEditComment = async (e) => {
        if (e) {
            e.preventDefault();
        }
        console.log('Updating comment...')
        if (com_text.trim() == '') {
            return
        }
        try {
            const form = new FormData()
            form.append('com_user', com_user)
            form.append('com_sond', com_sond)
            form.append('com_type', com_type)
            form.append('com_text', textComment)
            form.append('com_parent', com_parent)
            form.append('sub_com_id', sub_com_id)

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/comments/${com_id}?_method=PUT`, form,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        ...accessToken,
                    },
                }
            );
            console.log('Response send comment:', response.data)
            if (response.status === 200) {
                if (response.data.success) {
                    updateOpinion(response.data)
                }
            }
            setShowModalEdit(false)
        } catch (error) {
            console.error('Send comment catch error:', error.response.data);
        }

    }

    const deleteComment = async () => {
        try {
            const request = await api(`api/comments/${com_id}`, 'DELETE', null, accessToken)
            const response = await request.json()
            console.log('confirm delete:', response?.opinion?.last_comment)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    updateOpinion(response?.opinion)
                }
            }
        } catch (error) {
            if (error.message === 'Network request failed') {
                deleteComment()
            }
            console.log('Error delete comment:', error)
        }
    }


    const handleReply = () => {
        if (sourceFrom == 'Feed') {
            setShowUnauthModal(true)
            return
        }
        setIsReply(!isReply)
    }

    const handleReport = () => {
        if (sourceFrom == 'Feed') {
            setShowUnauthModal(true)
            return
        }
    }
    const handleEdit = () => {
        if (sourceFrom == 'Feed') {
            setShowUnauthModal(true)
            return
        }
        setShowModalEdit(true)
    }

    return (
        <>
            <div className='comment'>

                {com_anonymous ?
                    <img src={require('../../assets/images/background_opinion.jpg')}
                        className='profile-picture-componant'
                        width={35}
                        height={35}
                        style={{width: '35px', height: '35px', borderRadius: '100%', backgroundColor: 'white' }}
                        alt=""
                    />
                    :
                    <ProfilePicture
                        user={user}
                        uri={user?.profile?.prof_picture}
                        size={35}
                    />
                }

                <div className='comment-box'>

                    <div className='comment-text'>
                        <div className='user-box'>
                            {com_anonymous ?
                                <h1 className='user-name'>
                                    {i18n.language == 'en'
                                        ? 'Anonymous'
                                        : 'Anonyme'
                                    }
                                </h1>
                                :
                                <h1 className='user-name'>
                                    {user?.user_surname || ''} {user?.user_name || ''}
                                </h1>
                            }
                            <span className='timestamp'><Moment fromNow>{com_date_created}</Moment></span>
                        </div>
                        <p className='text'>
                            {com_text}


                        </p>
                        <TextTranslate text={com_text} />
                    </div>

                    <div className='buttons-box'>

                        <button onClick={handleLikeComment} style={{ color: isLiked ? '#0570E7' : '#808080' }} className='comment-button'>
                            {soutiensCountState != 0 ? `Like ${soutiensCountState}` : `Like`}
                        </button>

                        <button onClick={handleReply} className='comment-button'>
                            {subcomments_count != 0 ? `Reply ${subcomments_count}` : `Reply`}
                        </button>

                        {AuthData?.user?.user_id == user?.user_id ?
                            <div>
                                <button onClick={handleEdit} className='comment-button'>
                                    Edit
                                </button>
                                <button onClick={deleteComment} className='comment-button'>
                                    Delete
                                </button>
                            </div>
                            :
                            <button onClick={handleReport} className='comment-button'>
                                Report
                            </button>
                        }
                    </div>
                </div>

            </div>

            {isReply &&
                <div style={{ marginLeft: '10%', marginRight: '3%' }}>
                    <CommentInput
                        sond_id={sond_id}
                        sond_user={sond_user}
                        sond_caption={sond_caption}
                        com_user={com_user}
                        com_type={'c'}
                        com_parent={com_id}
                        sub_com_id={sub_com_id}
                        updateOpinion={updateOpinion}
                        setShowUnauthModal={setShowUnauthModal}
                        sourceFrom={sourceFrom}
                    />
                </div>
            }

            <Modal show={showModalEdit}>
                <Header title='Edit comment' type='modal' goBack={() => setShowModalEdit(false)} />
                <div>
                    <form onSubmit={hanndleEditComment} className='comment-input-component'>
                        <ProfilePicture
                            user={AuthData?.user}
                            uri={AuthData?.user?.profile?.prof_picture}
                            size={33}
                        />
                        <InputEmojiWithRef
                            value={textComment}
                            onChange={setTextComment}
                            cleanOnEnter
                            keepOpened
                            onEnter={text => hanndleEditComment()}
                            theme={'light'}
                            placeholder="Écrivez votre commentaire"
                        />
                        <button type='submit' onClick={hanndleEditComment} >
                            <RiSendPlaneFill size={26} color={color.primary} style={{ backgroudColor: color.primary, border: `1px ${color.primary} solid`, borderRadius: '30px', padding: '3px' }} />
                        </button>
                    </form>
                </div>
            </Modal>

        </>
    )
}

export default memo(Comment)