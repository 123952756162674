import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './AppLayout.module.css'
import Endbar from '../../components/Endbar/Endbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import NavigationBottom from 'components/NavigationBottom/NavigationBottom'
import ChatIncomingCall from 'components/ChatIncomingCall/ChatIncomingCall'

function AppLayout() {
    return (
        <div className='flex align-center overflow-hidden h-screen'>
            <div className={styles.sidebar}>
                <Sidebar />
            </div>
            <div className={styles.outlet}>
                <Outlet />
                <ChatIncomingCall/>
                <NavigationBottom />
            </div>
            <div className={styles.endbar}>
                <Endbar />
            </div>
        </div>
    )
}

export default AppLayout