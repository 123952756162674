import AgoraRTC from "agora-rtc-sdk-ng";
import { api, apiUrl, appUrl } from 'api/api';
import styles from './IncomingCall.module.css'
import { agorakeys } from 'constants/agorakeys';
import { agoraAppID, scheme } from 'constants/env';
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { generateChannelName } from "hooks/Utils/Utils";
import axios from 'axios'
import dayjs from "dayjs";
import { firestoreDB } from "services/Firebase/firebase";
import firestorekeys from "constants/firestorekeys";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { RemoteUser } from "agora-rtc-react";
import { generateBroadcastToken } from "services/Agora/AgoraService";
import { BsCameraVideo, BsCameraVideoOffFill } from "react-icons/bs";
import { PiMicrophone, PiMicrophoneSlash, PiMicrophoneSlashFill } from "react-icons/pi";
import { BiSolidMicrophone } from "react-icons/bi";
import { ImPhoneHangUp } from "react-icons/im";

const callStatus = {
  start: 'start',
  ringing: 'ringing',
  connexion: 'connexion',
  received: 'received',
  decline: 'decline',
  accepted: 'accepted',
  already: 'already',
  ended: 'ended',
}

const IncomingCall = () => {

  const { t, i18n } = useTranslation()
  const accessToken = useAccessToken()
  const { data: authContextState } = useAuthReducer()
  const navigate = useNavigate();
  const location = useLocation();

  const {
    conv_id
  } = useParams();

  const {
    channel,
    token,
    type,
    data
  } = location?.state;


  // AGORA CLIENT
  const client = useRef(null)
  const localMicrophoneTrack = useRef(null);
  const localCameraTrack = useRef(null);

  const [appID, setAppID] = useState(agoraAppID)
  const [uid, setUid] = useState(0)


  // LOCAL USER
  const [callType, setCallType] = useState(type || 'audio');
  const [caller, setCaller] = useState(data?.caller);
  const [remoteUser, setRemoteUser] = useState([]);

  const [started, setStarted] = useState(false);
  const [joined, setJoined] = useState(false);
  const [status, setStatus] = useState(callStatus.start);

  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const timerRef = useRef(null);



  useEffect(() => {
    const agoaraInit = async () => {
      try {
        if (started) return

        setStarted(true)

        // Create agora client
        const agoraClient = AgoraRTC.createClient({
          mode: "rtc",
          codec: "vp8",
          logConfig: {
            level: 2, // Suppresses all logs
            // Options:
            // 0: NONE
            // 1: CRITICAL
            // 2: ERROR
            // 3: WARNING
            // 4: INFO (default)
            // 5: DEBUG
          },
        });
        client.current = agoraClient
        agoraClient.setClientRole(agorakeys.broadcasterRole)

        // Genarate channel name and token
        const broadcastToken = await generateBroadcastToken();
        if (broadcastToken) {
          try {
            // Join channel
            const user = await client.current.join(
              appID,
              channel,
              token,
              uid || null
            )
            setUid(user)
          } catch (joinError) {
            console.error('Error joining the channel:', joinError);
          }
        } else {
          alert('Failed to generate a broadcast token');
        }

        // Create media track
        const [localAudioTrack, localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
        localAudioTrack.setEnabled(true)
        localVideoTrack.setEnabled(callType == 'video')

        localMicrophoneTrack.current = localAudioTrack
        localCameraTrack.current = localVideoTrack

        localVideoTrack.play("local-stream");


        // EVENT LISTENER
        agoraClient.on("user-joined", (user) => {
          console.log("user-joined:", user);
          setJoined(true)
        })

        agoraClient.on("user-published", async (user, mediaType) => {

          // Subscribe to a remote user
          await agoraClient.subscribe(user, mediaType);
          console.log("subscribe success", user.uid);

          if (mediaType === "video" || mediaType === "all") {
            // Get `RemoteVideoTrack` in the `user` object.
            const remoteVideoTrack = user.videoTrack;
            console.log(remoteVideoTrack);

            user.videoTrack.play(`${user.uid}`);
            remoteVideoTrack.play("remote-stream");

            setRemoteUser(oldValue =>
              oldValue.some(item => item?.uid == user.uid)
                ? oldValue
                : [...oldValue, remoteVideoTrack]
            )
          }

          if (mediaType === "audio" || mediaType === "all") {
            // Get `RemoteAudioTrack` in the `user` object.
            const remoteAudioTrack = user.audioTrack;
            // Play the audio track. Do not need to pass any DOM element
            remoteAudioTrack.play();
          }
        });

        agoraClient.on("user-unpublished", (user) => {
          console.log("user-unpublished:", user);
        });

        agoraClient.on("user-left", (user) => {
          console.log("user-left:", user);
          setStatus(callStatus.ended)
          leaveChannel()
        });

        //  PUBLISH
        await client.current.publish([localAudioTrack, localVideoTrack])
      } catch (error) {
        console.error('Error init agora', error);
      }
    }

    agoaraInit()

    return () => {
      leaveChannel()
    };
  }, []);


  const checkCallStatus = async () => {
    try {
      // Reference the document
      const docRef = doc(
        firestoreDB,
        scheme,
        firestorekeys.calls,
        firestorekeys.channels,
        conv_id
      );

      // Fetch the document
      const docSnap = await getDoc(docRef);

      // Check if the document exists
      if (docSnap.exists()) {
        if (docSnap.data()?.call_finished) {
          console.log("Call do not finished!");
          
        } else {
          console.log("User is already in calling!");
          setStatus(callStatus.already)
          
        }
      } else {
        console.log("Call do not exist!");
      }
    } catch (error) {
      console.error("Error checking document:", error);
      leaveChannel()
      throw error;
    }
  };


  const leaveChannel = async () => {
    try {
      if (!client.current) return;
      setJoined(false)

      localMicrophoneTrack.current && localMicrophoneTrack.current?.stop();
      localCameraTrack.current && localCameraTrack.current?.stop();
      await client.current?.unpublish([localMicrophoneTrack.current, localCameraTrack.current]);


      localMicrophoneTrack.current && localMicrophoneTrack.current?.close();
      localCameraTrack.current && localCameraTrack.current?.close();

      await client.current?.removeAllListeners();
      await client.current?.leave();

      setJoined(false);
      localMicrophoneTrack.current = null;
      localCameraTrack.current = null;

      // Finish firestore call
      const docRef = doc(firestoreDB, scheme, firestorekeys.calls, firestorekeys.channels, conv_id);

      await updateDoc(docRef, { call_finished: true }).then(() => {
        console.log('Call finished to firstore.');
      }).catch((error) => {
        console.error('Error Call finished:', error);
      });

      setTimeout(() => {
        navigate(-1)
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };


  const toggleCamera = () => {
    try {
      if (cameraOn) {
        setCameraOn(false)
        localCameraTrack?.current?.setEnabled(false)
      } else {
        setCameraOn(true)
        localCameraTrack?.current?.setEnabled(true)
      }
    } catch (error) {
      console.error(error);

    }
  }

  const toggleMicro = () => {
    try {
      if (micOn) {
        setMicOn(false)
        localMicrophoneTrack?.current?.setEnabled(false)
      } else {
        setMicOn(true)
        localMicrophoneTrack?.current?.setEnabled(true)
      }
    } catch (error) {
      console.error(error);

    }
  }


  useEffect(() => {
    if (!joined) return

    timerRef.current = setInterval(() => {
      setElapsedTime(oldValue => oldValue + 1);
    }, 1000);

    return () => clearInterval(timerRef.current); // Cleanup on unmount
  }, [joined]);

  // Format elapsed time in HH:MM:SS
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div className={` h-screen bg-black`}>

      {/* Remote video stream */}
      {joined &&
        <div
          id="remote-stream"
          className={`remote-stream w-full h-screen bg-black`}>
        </div>
      }


      {/* Local video stream */}
      <div
        id="local-stream"
        className={joined
          ? `${styles.localStreamJoined} local-stream absolute bg-black shadow-sm`
          : `local-stream w-full h-screen bg-black`
        }>
      </div>

      <div className={`${styles.container} absolute top-0 h-screen `}>
        <div styl className='flex justify-center mt-6'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
          </svg>
          <p className='text-center text-white text-sm mx-3'>
            {i18n.language == 'en'
              ? 'End-to-end encrypted'
              : 'Chiffré de bout en bout'
            }
          </p>
          <img
            src={require('assets/app/icon.png')}
            style={{
              width: 18,
              height: 18
            }}
          />
        </div>


        {/* Call status */}
        <div className='mt-10'>
          <p className='text-center text-white font-bold text-2xl'>
            {caller?.user_surname} {caller?.user_name}
          </p>

          {joined &&
            <p className='text-center text-white font-semibold text-sm mt-3'>
              {formatTime(elapsedTime)}
            </p>
          }

          {(!joined && status == callStatus.start) &&
            <p className='text-center text-white font-semibold text-sm mt-3'>
              {i18n.language == 'en'
                ? 'Requesting...'
                : 'Demande...'
              }
            </p>
          }

          {(!joined && status == callStatus.ringing) &&
            <p className='text-center text-white font-semibold text-sm mt-3'>
              {i18n.language == 'en'
                ? 'Ringing...'
                : 'Sonnerie...'
              }
            </p>
          }

          {(!joined && status == callStatus.connexion) &&
            <p className='text-center text-white font-semibold text-sm mt-3'>
              Connexion...
            </p>
          }

          {(!joined && status == callStatus.already) &&
            <p className='text-center text-white font-semibold text-sm mt-3'>
              {i18n.language == 'en'
                ? 'Already online'
                : 'Déjà en ligne'
              }
            </p>
          }

          {(!joined && status == callStatus.ended) &&
            <p className='text-center text-white font-semibold text-sm mt-3'>
              {i18n.language == 'en'
                ? 'Call ended'
                : 'Appel terminé'
              }
            </p>
          }
        </div>


        {type == 'audio' &&
          <div className='mt-32'>
            <img
              src={caller?.profile?.prof_picture || require('assets/images/background_opinion.jpg')}
              className='w-32 h-32 rounded-full mx-auto'
              alt=''
            />
          </div>
        }

        <div className='absolute flex justify-center w-full bottom-0'>

          <button
            onClick={toggleMicro}
            className='m-4 bg-white text-gray-800 p-3 rounded-full'>
            {micOn ?
              <PiMicrophone
                size={26}
              />
              :
              <PiMicrophoneSlashFill
                size={26}
              />
            }
          </button>

          {callType == 'video' &&
            <button
              onClick={toggleCamera}
              className='m-4 bg-white text-gray-800 p-3 rounded-full'>
              {cameraOn ?
                <BsCameraVideo
                  size={28}
                />
                :
                <BsCameraVideoOffFill
                  size={28}
                />
              }
            </button>
          }

          <button
            onClick={() => {
              setStatus(callStatus.ended)
              leaveChannel()
            }}
            className='m-4 bg-red-600 text-white p-3 rounded-full'>
            <ImPhoneHangUp
              size={28}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default IncomingCall