import React, { useEffect, useState } from 'react'
import styles from './Intranet.module.css'
import Header from '../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ModelAlert from '../../components/ModalAlert/ModelAlert'
import { IoIosGlobe } from "react-icons/io";
import { color } from 'hooks/Utils/color'
import { TbApiApp } from "react-icons/tb";
import { api } from 'api/api'


const Intranet = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
    const navigate = useNavigate()

    const INTRANET_URL = process.env.REACT_APP_INTRANET_URL

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)


    const [youtubeLink, setYoutubeLink] = useState('https://www.youtube.com/embed/FEixTwDeIYQ')
    const [showAlert, setShowAlert] = useState(false)


    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)
    const handleShowAlert = () => setShowAlert(true)
    const goToRequest = () => navigate('/intranet/request')
    const goToHolidayBalance = () => navigate('/intranet/holiday-balance')
    const goToEvents = () => navigate('/intranet/events')
    const goToCalendar = () => navigate('/intranet/calendar')
    const goToIntranet = () => window.open(INTRANET_URL, "_self");
    const goToSharedDocs = (link) => {
        window.open(link, "_self");
    }
    const goToAIAssistant = () => navigate('/intranet/ai-assistant')
    const goToYoutube = () => window.open(
        youtubeLink || 'https://www.youtube.com/embed/FEixTwDeIYQ',
        "_self"
    )


    useEffect(() => {
        const getAppSetting = async () => {
            try {
                const request = await api(`api/app-settings`, 'GET', null, accessToken);
                const response = await request.json()
                console.log('Get app settings response:', response?.setting_youtube_link)
                if (request?.status === 200) {
                    setYoutubeLink(response?.setting_youtube_link)
                }
            } catch (error) {
                setTimeout(() => {
                    getAppSetting()
                }, 2000);
                console.error('Error app settings response:', error);
            }
        }
        getAppSetting()
    }, [])

    return (
        <div className={styles.screen}>
            <Header title='Intranet' goBack={goBack} />
            <div className={styles.container}>

                <div
                    onClick={() => window.open('https://jeparleaudg.tresor.ci/', "_self")}
                    className={styles.pressable}>
                    <img src={require('assets/app/icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            {i18n.language == 'en'
                                ? 'I talk freely to the CEO'
                                : 'Je parle librement au DG'
                            }
                        </h6>
                        <p className={styles.descPressable}>
                            {i18n.language == 'en'
                                ? 'Speak directly to the Director General of the Public Treasury.'
                                : 'Parler directement au Directeur Général du Trésor Public.'
                            }
                        </p>
                    </div>
                </div>

                <div
                    onClick={() => window.open('https://jeparleaudg.tresor.ci/', "_self")}
                    className={styles.pressable}>
                    <img src={require('assets/app/icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            {i18n.language == 'en'
                                ? 'Youtube Public Treasury'
                                : 'Youtube Trésor Publique'
                            }
                        </h6>
                        <p className={styles.descPressable}>
                            {i18n.language == 'en'
                                ? 'Youtube page of the Public Treasury of Ivory Coast.'
                                : 'Page Youtube du Trésor Publique de côte d\'ivoire.'
                            }
                        </p>
                    </div>
                </div>

                <div
                    onClick={goToYoutube}
                    className={styles.pressable}>
                    <img src={require('assets/images/icon_youtube.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            {t('officialWebsiteTitle')}
                        </h6>
                        <p className={styles.descPressable}>
                            {t('officialWebsiteDesc')}
                        </p>
                    </div>
                </div>

                <div onClick={goToRequest} className={styles.pressable}>
                    <img src={require('../../assets/icons/rh_request_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myRequests')}</h6>
                        <p className={styles.descPressable}>{t('myRequestDesc')}</p>
                    </div>
                </div>

                <div onClick={goToHolidayBalance} className={styles.pressable}>
                    <img src={require('../../assets/icons/balance_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myBalance')}</h6>
                        <p className={styles.descPressable}>{t('myBalanceDesc')}</p>
                    </div>
                </div>

                <div onClick={goToEvents} className={styles.pressable}>
                    <img src={require('../../assets/icons/event_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myEvents')}</h6>
                        <p className={styles.descPressable}>{t('myEventDesc')}</p>
                    </div>
                </div>

                <div onClick={goToCalendar} className={styles.pressable}>
                    <img src={require('../../assets/icons/calendar_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myCalendar')}</h6>
                        <p className={styles.descPressable}>{t('myCalendarDesc')}</p>
                    </div>
                </div>




                <div
                    onClick={handleShowAlert}
                    className={styles.pressable}>
                    <TbApiApp size={40} color={color.success} style={{ marginRight: '15px' }} />
                    <div>
                        <h6 className={styles.titlePressable}>
                            {t('tresorIntelligence')}
                        </h6>
                        <p className={styles.descPressable}>
                            {t('tresorIntelligenceDesc')}
                        </p>
                    </div>
                </div>

                <div onClick={goToAIAssistant} className={styles.pressable}>
                    <img src={require('../../assets/icons/chat_gpt.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myAIAssistant')}</h6>
                        <p className={styles.descPressable}>{t('myAIAssistantDesc')}</p>
                    </div>
                </div>


                <div
                    onClick={() => window.open('https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=5e3ce6c0-2b1f-4285-8d4b-75ee78787346&scope=openId%20profile%20openid%20offline_access&redirect_uri=https%3A%2F%2Fteams.microsoft.com%2Fv2&client-request-id=89c5d01c-1b34-4976-8177-7607d5620fc4&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=3.7.1&client_info=1&code_challenge=X3Uhpjx6OFmGQAdIX82pwuWC0fD0zENooEzyvfeYalY&code_challenge_method=S256&prompt=select_account&nonce=fe831c7e-edb0-49e0-a29d-1dd149cc4ebd&state=eyJpZCI6ImZjMjRhYTRmLTFjMTEtNDgzZS05NTRiLWM0ZmNiZjU1YzQ0YSIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D', "_self")}
                    className={styles.pressable}>
                    <img src={require('assets/images/microsoft_teams.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            Microsoft Teams
                        </h6>
                        <p className={styles.descPressable}>
                            {i18n.language == 'en'
                                ? 'Professional and secure videoconferencing service.'
                                : 'Service de visioconférence professionnel et sécurisé.'
                            }
                        </p>
                    </div>
                </div>

                <div
                    onClick={() => window.open('https://nakani.info', "_self")}
                    className={styles.pressable}>
                    <img src={require('assets/images/icon-nakani.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            Nakani Artificial Intelligence
                        </h6>
                        <p className={styles.descPressable}>
                            {i18n.language == 'en'
                                ? 'Explore the potential of African companies with confidence using our cutting-edge technology chatbot !'
                                : 'Explorez le potentiel des entreprises africaines en toute confiance avec notre chatbot à l\'avant-garde de la technologie !'
                            }
                        </p>
                    </div>
                </div>

            </div>


            <ModelAlert
                message={userLanguage == 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />

        </div>
    )

}

export default Intranet